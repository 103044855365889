import React, { useContext, useEffect, useState } from "react";
import { SWRConfig } from "swr";
import Loading from "./Loading";

const code_param_name = "__csst";
const sessionStorageKey = "curr-uat";
// const oneDay = 86400000;
const oneMinute = 60_000;

let getSessionStorage = () => sessionStorage?.getItem(sessionStorageKey);

let setSessionStorage = (token, expiresAt) =>
  sessionStorage?.setItem(
    sessionStorageKey,
    JSON.stringify({ token, exp: expiresAt })
  );
let delSessionStorage = () => sessionStorage?.removeItem(sessionStorageKey);
let fetchTokenIfNotExpiringSoon = () => {
  let stored = getSessionStorage();
  if (stored) {
    try {
      let { token, exp } = JSON.parse(stored);
      if (exp - Date.now() > oneMinute * 60) {
        return token;
      }
    } catch (e) {}
  }
};

export const DecodeContext = React.createContext({
  token: "",
  onError: () => {},
  logout: () => {},
});

let DecodeProvider = ({
  swrConfig,
  cacheToken,
  env,
  children,
}) => {
  let [token, setToken] = useState("");
  let [shouldRedirect, setShouldRedirect] = useState(false);

  // if (cacheToken === undefined) {
  //   if (process.env.NODE_ENV === "production") {
  //     cacheToken = false;
  //   } else {
  //     cacheToken = true;
  //   }
  // }

  let onError = (error) => {
    switch (error) {
      case 401: {
        setShouldRedirect(true);
        return;
      }
      default: {
        throw new Error(`No error handler registered for error code: ${error}`);
      }
    }
  };

  useEffect(() => {
    let doEffect = async () => {
      let storedToken = cacheToken && fetchTokenIfNotExpiringSoon();
      let { [code_param_name]: code, ...rest } = getParams();

      if (code) {
        // let { token, expiresAt } = await exchangeCode(code);
        const { token: returnedToken, expiresAt } = await checkToken(code);
        cacheToken && setSessionStorage(returnedToken, expiresAt);
        let { origin, pathname } = window.location;
        let search = encodeParams(rest);
        let url = search ? origin + pathname + "?" + search : origin + pathname;
        window.history.pushState({}, "", url);
        setToken(returnedToken); // hack -- set state after window.pushState() to force re-render
      } else if (storedToken) {
        setToken(storedToken);
      } else {
        console.log("no token");
        setShouldRedirect(true);
      }
    };
    doEffect();
  }, [cacheToken]);

  useEffect(() => {
    if (shouldRedirect) {
      if (window.location.hostname === 'localhost') {
        window.location.href =
        `http://localhost:3000/external-auth-access?source=c4&token_key=${encodeURIComponent(code_param_name)}&redirect_url=${window.encodeURIComponent(
          window.location.href
        )}`;
      } else
      window.location.href =
        `https://app.current.tech/external-auth-access?source=c4&token_key=${encodeURIComponent(code_param_name)}&redirect_url=${window.encodeURIComponent(
          window.location.href
        )}`;
    }
  }, [shouldRedirect]);

  let logout = (redirectUrl) => {
    delSessionStorage();
    if (redirectUrl) {
      if (window.location.hostname === 'localhost') {
        window.location.href = `http://localhost:3000/auth/logout?redirect_url=${window.encodeURIComponent(
          redirectUrl
        )}`;
      }
      window.location.href = `https://app.current.tech/auth/logout?redirect_url=${window.encodeURIComponent(
        redirectUrl
      )}`;
    } else {
      if (window.location.hostname) {
        window.location.href = `http://localhost:3000/auth/logout`;
      }
      window.location.href = `https://app.current.tech/auth/logout`;
    }
  };

  if (!token) {
    return <Loading msg="Logging you in..." />;
  }

  return (
    <DecodeContext.Provider
      value={{ initialized: true, logout, token, env, onError }}
    >
      <SWRConfig value={swrConfig ?? {}}>{children}</SWRConfig>
    </DecodeContext.Provider>
  );
};

let checkToken = async (code) => {
  let res = await fetch("https://app.current.tech/auth/public/check_token", {
    method: "POST",
    body: JSON.stringify({ code }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!res.ok) {
    throw new Error(
      `Received an unexpected error check token (${res.status}): ${res.body}`
    );
  }
  const { payload } = await res.json();
  let { token, expiresAt } = payload;
  return { token, expiresAt };
};

// let exchangeCode = async (
//   code
// ) => {
//   let res = await fetch("https://api.usedecode.com/auth/exchange_code", {
//     method: "POST",
//     body: JSON.stringify({ code }),
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
//   if (!res.ok) {
//     throw new Error(
//       `Received an unexpected error exchange decode code for token (${res.status}): ${res.body}`
//     );
//   }
//   let { token, expires_at: expiresAt } = await res.json();
//   return { token, expiresAt: expiresAt * 1000 };
// };

const getParams = () => {
  let qd = {};
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    for (let [key, value] of params) {
      qd[key] = decodeURIComponent(value);
    }
  }
  return qd;
};

const encodeParams = (p) =>
  Object.entries(p)
    .map((kv) => kv.map(encodeURIComponent).join("="))
    .join("&");

let useDecodeContext = () => {
  let ctx = useContext(DecodeContext);

  if (!ctx.initialized) {
    throw new Error(
      "You tried to use a Decode hook (eg `useDecode`) without having a parent <DecodeProvider /> component in the tree. Please wrap your app in <DecodeProvider /> near the top of the component tree, like this: <DecodeProvider><App /></DecodeProvider>."
    );
  }

  return ctx;
};

export let useToken = () => useDecodeContext().token;
export let useEnv = () => useDecodeContext().env;
export let useOnError = () => useDecodeContext().onError;
export let useLogout = () => useDecodeContext().logout;

export default DecodeProvider;
