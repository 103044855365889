import React from 'react';
import PropTypes from 'prop-types';
import { useDecode } from '../../Decode';
import {
    FacebookOutlined,
    InstagramOutlined,
    YoutubeOutlined,
    TwitterOutlined
} from '@ant-design/icons';
import { Spin } from 'antd';

export default function SocialLinks({ influencerId }) {
    const socialParser = (results) => {
        const parsedSocialNetorks = {};
        // eslint-disable-next-line
        results.map(function (s) {
            if ((s.url !== null) && (s.url !== '')) {
                if (s.network === 'facebook') {
                    parsedSocialNetorks.facebook = s.url;
                }
                if (s.network === 'instagram') {
                    parsedSocialNetorks.instagram = s.url;
                }
                if (s.network === 'twitter') {
                    parsedSocialNetorks.twitter = s.url;
                }
                if (s.network === 'youtube') {
                    parsedSocialNetorks.youtube = s.url;
                }
            } else parsedSocialNetorks[s.network] = null;
        });
        return parsedSocialNetorks;
    };

    const socialLinkParams = { influencerId };

    const { data: socialLinks, error: getSocialLinksError } = useDecode(
        ['getClientSocialNetworks', socialLinkParams],
        socialParser
    );

    if (getSocialLinksError) return <div>Failed to get get Social Links</div>;
    if (!socialLinks) {
        return (
            <div className="loader">
                <Spin size="small" />
            </div>
        );
    }

    return (
        <div className="social-links">
            {
                socialLinks.facebook !== null
                    ? (
                        <a
                            href={`${socialLinks.facebook}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FacebookOutlined style={{ color: '#1890ff', fontSize: '1.7em' }} />
                        </a>
                    )
                    : null
            }
            {
                socialLinks.instagram !== null
                    ? (
                        <a
                            href={`${socialLinks.instagram}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <InstagramOutlined style={{ color: '#1890ff', fontSize: '1.7em' }} />
                        </a>
                    )
                    : null
            }
            {
                socialLinks.youtube !== null
                    ? (
                        <a
                            href={`${socialLinks.youtube}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <YoutubeOutlined style={{ color: '#1890ff', fontSize: '1.7em' }} />
                        </a>
                    )
                    : null
            }
            {
                socialLinks.twitter !== null
                    ? (
                        <a
                            href={`${socialLinks.twitter}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TwitterOutlined style={{ color: '#1890ff', fontSize: '1.7em' }} />
                        </a>
                    )
                    : null
            }
        </div>
    );
}

SocialLinks.propTypes = {
    influencerId: PropTypes.number.isRequired
};
