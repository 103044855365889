import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    Form,
    Input,
    InputNumber
} from 'antd';

export default function NewGroupModal({ visible, onCreate, onCancel }) {
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            title="Add New Group"
            okText="Save new group"
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then((newGroup) => {
                        form.resetFields();
                        onCreate(newGroup);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form form={form} layout="vertical" name="newUserModal">
                <Form.Item
                    label="Group Name"
                    name="groupName"
                    rules={[{ required: true, message: 'A group name is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Flat Fee"
                    name="flatFee"
                    rules={[{ required: true, message: 'Flat fee is required' }]}
                >
                    <InputNumber
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        step={0.01}
                    />
                </Form.Item>
                <Form.Item
                    label="% of Sales"
                    name="percentOfSales"
                    rules={[{ required: true, message: 'Percent of sale is required' }]}
                >
                    <InputNumber
                        min={0}
                        max={100}
                        step={0.01}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace('%', '')}
                    />
                </Form.Item>
                <Form.Item
                    label="Bill Date"
                    name="billDate"
                    rules={[{ required: true, message: 'Payout Date is required' }]}
                >
                    <InputNumber min={1} max={30} />
                </Form.Item>
            </Form>
        </Modal>
    );
}

NewGroupModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCreate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};
