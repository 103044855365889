import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDecode, useFetcher } from '../../Decode';
import {
    Button,
    Table,
    Spin,
    notification
} from 'antd';
import {
    PlusCircleOutlined,
    SmileOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import NewGroupModal from './NewGroupModal';

export default function Groups({ clientId }) {
    const fetcher = useFetcher();

    const [groupModalVisible, setGroupModalVisible] = useState(false);

    const { data: groups, error: getGroupsError, mutate } = useDecode(
        ['getGroupsByClientId', { clientId }],
        groupsParser
    );

    const refreshGroups = (newGroup) => {
        mutate([
            ...groups,
            {
                groupName: newGroup.groupName,
                flatFee: `$${newGroup.flatFee}/monthly`,
                percentOfSales: `${newGroup.percentOfSales}%`,
                payoutDay: newGroup.billDate
            }
        ]);
    };

    if (getGroupsError) return <div>Failed to get Groups</div>;
    if (!groups) {
        return (
            <div className="loader" style={loadStyle}>
                <Spin size="large" tip="Loading Client" />
            </div>
        );
    }

    const onCreate = async (newGroup) => {
        try {
            await fetcher('createNewGroup', {
                groupName: newGroup.groupName,
                clientId,
                flatFee: Number(newGroup.flatFee.toFixed(2)) * 100,
                percentOfSales: (Number(newGroup.percentOfSales.toFixed(2)) / 100).toFixed(4),
                payoutDay: newGroup.billDate
            });
            setGroupModalVisible(false);
            refreshGroups(newGroup);
            notification.open({
                message: 'Saved Successfully!',
                description: `Group ${newGroup.groupName} has been successfully saved!`,
                icon: <SmileOutlined style={{ color: '#52c41a' }} />
            });
        } catch (error) {
            console.log(error);
            notification.open({
                message: 'Not Saved!',
                description: `We encountered an error saving ${newGroup.groupName}`,
                icon: <ExclamationCircleOutlined style={{ color: '#f5222d' }} />
            });
        }
    };

    return (
        <>
            <div className="group">
                <Button
                    shape="round"
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                        setGroupModalVisible(true);
                    }}
                >
                    Add New Group
                </Button>
                <NewGroupModal
                    visible={groupModalVisible}
                    onCancel={() => {
                        setGroupModalVisible(false);
                    }}
                    onCreate={onCreate}
                />
                <Table
                    dataSource={groups}
                    columns={columns}
                    size="small"
                    pagination={{ hideOnSinglePage: true }}
                />
            </div>
            <style jsx>
                {`
                    .group {
                        color: #fff;
                        display: grid;
                        grid-template-row: auto auto;
                        grid-row-gap: 24px;
                        margin-bottom: 24px;
                    }
                    .group .ant-btn-round {
                        width: fit-content;
                        justify-self: start;
                    }
                `}
            </style>
        </>
    );
}

const loadStyle = {
    width: '100%',
    height: '100vh',
    display: 'grid',
    alignItems: 'center'
};

let groupsParser = (results) => results.map((r) => ({
    key: r.id,
    groupName: r.name,
    flatFee: r.defaultFlatFee ? `$${(r.defaultFlatFee / 100).toFixed(2)}/monthly` : '$0/monthly',
    percentOfSales: r.defaultPercentOfSales ? `${(r.defaultPercentOfSales * 100).toFixed(2)}%` : '0%',
    payoutDate: r.defaultPayoutDay
}));

const columns = [
    {
        title: 'Group',
        dataIndex: 'groupName',
        key: 'groupName'
    },
    {
        title: 'Flat fee',
        dataIndex: 'flatFee',
        key: 'flatFee'
    },
    {
        title: '% of Sale',
        dataIndex: 'percentOfSales',
        key: 'percentOfSales'
    },
    {
        title: 'Payout Day of Month',
        dataIndex: 'payoutDate',
        key: 'payoutDate'
    }
];

Groups.propTypes = {
    clientId: PropTypes.string.isRequired
};
