import { useCallback, useContext } from 'react';
import UserContext from '../context/userContext';
import { useChurnZero } from '../context/churnZeroContext';

const useEventTracking = () => {
    const {
        methods,
        isReady,
    } = useChurnZero();

    const user = useContext(UserContext);

    const role = user?.activeClient?.userClients?.role;

    const shouldEventTrack = useCallback(
        (userRole) => {
            if (isReady) {
                if (
                    userRole === 'admin' ||
                    userRole === 'user' ||
                    userRole === 'internal_ambassador' ||
                    userRole === 'internal_client' ||
                    userRole === 'internal_cs'
                ) {
                    return true;
                }
            }
            return false;
        },
        [isReady],
    );

    const trackEvent = useCallback(
        (eventName, description, quantity, customFields) => {
            if (isReady && shouldEventTrack(role) && methods?.trackEvent) {
                methods.trackEvent(eventName, description, quantity, customFields);
            }
        },
        [isReady, methods?.czTrackEvent, shouldEventTrack, role],
    );

    const setAttribute = useCallback(
        (entity, attribute, value) => {
            if (isReady && methods?.czSetAttribute) {
                methods.czSetAttribute(entity, attribute, value);
            }
        },
        [isReady, methods?.czSetAttribute],
    );

    return {
        trackEvent,
        setAttribute,
    };
};

export default useEventTracking;
