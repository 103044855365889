import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDecode, useFetcher } from '../../Decode';
import {
    Button,
    Table,
    Spin,
    notification
} from 'antd';
import { PlusCircleOutlined, ExclamationCircleOutlined, SmileOutlined } from '@ant-design/icons';
import NewUserModal from './NewUserModal';

export default function Users({ clientId, name }) {
    const [userModalVisible, setUserModalVisible] = useState(false);

    const fetcher = useFetcher();

    const { data: users, error: getUsersError } = useDecode(
        ['getUsersByClientId', { clientId }],
        getUsersParser
    );

    const { data: permissions, error: getPermissionsError } = useDecode(
        ['getAvailablePermissions'],
        getPermissionParser
    );

    const handleNewUser = async (newUser) => {
        try {
            const perms = {};
            newUser.permissions.forEach((p) => {
                perms[p] = true;
            });
            const result = await fetcher('createUser', {
                body: {
                    ...newUser,
                    permissions: perms,
                    clientId,
                    accountName: name,
                    userType: 'user'
                }
            });
            if (result.payload) {
                users.push(getUsersParser([result.payload]));
                notification.open({
                    message: 'User Created',
                    description: 'Invite has been sent!',
                    icon: <SmileOutlined style={{ color: '#52c41a' }} />
                });
                setUserModalVisible(false);
            } else throw new Error('user create Failed');
        } catch (e) {
            console.log(e);
            notification.open({
                message: 'Not Created!',
                description: 'We encountered an error creating!',
                icon: <ExclamationCircleOutlined style={{ color: '#f5222d' }} />
            });
            throw e;
        }
    };

    if (getUsersError) return <div>Failed to get users</div>;
    if (!users) {
        return (
            <div className="loader" style={loadStyle}>
                <Spin size="medium" />
            </div>
        );
    }

    return (
        <>
            <div className="user">
                <Button
                    disabled={getPermissionsError}
                    shape="round"
                    icon={<PlusCircleOutlined />}
                    onClick={() => {
                        setUserModalVisible(true);
                    }}
                >
                    Add New User
                    {' '}
                </Button>
                <NewUserModal
                    visible={userModalVisible}
                    onCreate={handleNewUser}
                    permissions={permissions}
                    onCancel={() => {
                        setUserModalVisible(false);
                    }}
                />
                <Table
                    dataSource={users}
                    columns={columns}
                    size="small"
                    pagination={{ hideOnSinglePage: true }}
                />
            </div>
            <style jsx>
                {`
                    .user {
                        color: #fff;
                        display: grid;
                        grid-template-row: auto auto;
                        grid-row-gap: 24px;
                        margin-bottom: 24px;
                    }
                    .user .ant-btn-round {
                        width: fit-content;
                        justify-self: start;
                    }
                `}
            </style>
        </>
    );
}

const loadStyle = {
    width: '100%',
    display: 'grid',
    alignItems: 'center'
};

let getUsersParser = (results) => results.map((u) => ({
    id: u.userId,
    firstName: u.firstName,
    lastName: u.lastName,
    email: u.email,
    superAdmin: u.superAdmin ? '✔️' : ''
}));

let getPermissionParser = (results) => results;

const columns = [
    {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName'
    },
    {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName'
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: 'Super Admin',
        dataIndex: 'superAdmin',
        key: 'superAdmin'
    }
];

Users.propTypes = {
    clientId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};
