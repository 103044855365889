import React from 'react';
import PropTypes from 'prop-types';
import { useDecode } from '../../Decode';
import { Spin } from 'antd';
import {
    FacebookFilled,
    InstagramFilled,
    TwitterSquareFilled,
    YoutubeFilled,
    BankFilled,
    DollarCircleFilled
} from '@ant-design/icons';

export default function Status({ clientId }) {
    const { data: progress, error: getProgressError } = useDecode([
        'getProgress',
        { clientId }
    ]);

    if (getProgressError) return <div>Failed to get oAuth Status</div>;
    if (!progress) {
        return (
            <div className="loader">
                <Spin size="small" />
            </div>
        );
    }
    return (
        <>
            <div className="status">
                <h3>oAuth Status</h3>
                <div className="status-list">
                    <div className="status-item">
                        {
                            progress[0].facebook
                                ? (
                                    <FacebookFilled style={{ color: '#52c41a' }} />
                                )
                                : (
                                    <FacebookFilled style={{ color: '#f5222d' }} />
                                )
                        }
                        Facebook
                    </div>
                    <div className="status-item">
                        {
                            progress[0].instagram
                                ? (
                                    <InstagramFilled style={{ color: '#52c41a' }} />
                                )
                                : (
                                    <InstagramFilled style={{ color: '#f5222d' }} />
                                )
                        }
                        Instagram
                    </div>
                    <div className="status-item">
                        {
                            progress[0].youtube
                                ? (
                                    <YoutubeFilled style={{ color: '#52c41a' }} />
                                )
                                : (
                                    <YoutubeFilled style={{ color: '#f5222d' }} />
                                )
                        }
                        YouTube
                    </div>
                    <div className="status-item">
                        {
                            progress[0].twitter
                                ? (
                                    <TwitterSquareFilled style={{ color: '#52c41a' }} />
                                )
                                : (
                                    <TwitterSquareFilled style={{ color: '#f5222d' }} />
                                )
                        }
                        Twitter
                    </div>
                    <div className="status-item">
                        {
                            progress[0].banking
                                ? (
                                    <BankFilled style={{ color: '#52c41a' }} />
                                )
                                : (
                                    <BankFilled style={{ color: '#f5222d' }} />
                                )
                        }
                        Banking
                    </div>
                    <div className="status-item">
                        {
                            progress[0].media
                                ? (
                                    <DollarCircleFilled style={{ color: '#52c41a' }} />
                                )
                                : (
                                    <DollarCircleFilled style={{ color: '#f5222d' }} />
                                )
                        }
                        Media Values
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    .status {
                        width: 100%;
                        background-color: #f0f5ff;
                        padding: 20px;
                        border: solid;
                    }
                    .status h3 {
                        text-align: center;
                    }
                    .status-list {
                        display: grid;
                        grid-gap: 5px;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: auto auto auto auto;
                        grid-auto-flow: column;
                    }
                    .status-item {
                        display: grid;
                        grid-template-columns: auto 1fr;
                        grid-column-gap: 5px;
                        align-items: center;
                    }
                `}
            </style>
        </>
    );
}

Status.propTypes = {
    clientId: PropTypes.string.isRequired
};
