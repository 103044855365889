import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import {
    CHURN_ZERO_APP_KEY,
    CHURN_ZERO_EVENT_TYPE,
    CHURN_ZERO_SDK_URL,
} from '../constants/churnZero';
import { IS_PROD } from '../constants/environment';

const {
    CRM_APP_KEY,
    CRM_EVENT_TRACK,
    CRM_SET_ATTRIBUTE,
    CRM_INCREMENT_ATTRIBUTE,
    CRM_SET_MODULE,
    CRM_URL_TRACKING,
    CRM_SILENT,
    CRM_OPEN,
    CRM_CLOSE,
    CRM_STOP,
    CRM_SET_CONTACT,
} = CHURN_ZERO_EVENT_TYPE;

const ChurnZeroContext = createContext({
    isReady: false,
});

const ChurnZeroProvider = ({ children }) => {
    const [methods, setMethods] = useState();
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        async function initialize() {
            console.log('cz init start');
            await embedScript(IS_PROD ? CHURN_ZERO_SDK_URL.PROD : CHURN_ZERO_SDK_URL.DEV);
            const churnZero = window.ChurnZero;
            if (!churnZero) {
                throw new Error('ChurnZero object is not initialized by embedded script.');
            }

            churnZero.push([
                CRM_APP_KEY,
                IS_PROD ? CHURN_ZERO_APP_KEY.PROD : CHURN_ZERO_APP_KEY.DEV,
            ]);
            setMethods(churnZero);
        }

        initialize();
    }, []);

    const trackEvent = useCallback(
        (...args) => {
            if (methods) methods.push([CRM_EVENT_TRACK, ...args]);
        },
        [methods],
    );

    const setCZAttributes = React.useCallback(({
        firstName,
        lastName,
        accountName,
        accountId,
        salesforceId,
        email
    }) => {
        if (methods && salesforceId && email) {
            methods.push([CRM_SET_CONTACT, salesforceId, email]);
            methods.push([CRM_SET_ATTRIBUTE, 'account', 'Name', accountName]);
            methods.push([CRM_SET_ATTRIBUTE, 'account', 'clientAccountId', accountId]);
            methods.push([CRM_SET_ATTRIBUTE, 'account', 'clientSalesforceId', salesforceId]);
            methods.push([
                CRM_SET_ATTRIBUTE,
                'contact',
                'ContactRole',
                'internal_cs',
            ]);
            methods.push([CRM_SET_ATTRIBUTE, 'contact', 'firstName', firstName]);
            methods.push([CRM_SET_ATTRIBUTE, 'contact', 'lastName', lastName]);
            setIsReady(true);
        }
    }, [methods]);

    async function embedScript(url) {
        return new Promise((resolve, reject) => {
            const f = document.getElementsByTagName('script')[0];
            const j = document.createElement('script');
            j.async = true;
            j.src = url;
            j.crossOrigin = 'anonymous';
            f.parentNode.insertBefore(j, f);

            j.onload = () => {
                resolve();
            };

            j.onerror = (event, __, ___, ____, error) => {
                reject(
                    new Error(
                        `Failed to load ChurnZero script. ${
                            typeof event == 'string' ? event : ''
                        } ${error?.message}`,
                    ),
                );
            };
        });
    }

    const setAttribute = useCallback(
        function setAttr(entity, name, value) {
            if (methods) methods.push([CRM_SET_ATTRIBUTE, entity, name, value]);
        },
        [methods],
    );

    const setContact = useCallback(
        (accountId, contactId) => {
            if (methods) methods.push([CRM_SET_CONTACT, accountId, contactId]);
        },
        [methods],
    );

    const incrementAttribute = useCallback(
        (entity, name, value) => {
            if (methods) methods.push([CRM_INCREMENT_ATTRIBUTE, entity, name, value]);
        },
        [methods],
    );

    const setModule = useCallback(
        (...args) => {
            if (methods) methods.push([CRM_SET_MODULE, ...args]);
        },
        [methods],
    );

    const urltracking = useCallback(
        (...args) => {
            if (methods) methods.push([CRM_URL_TRACKING, ...args]);
        },
        [methods],
    );

    const silent = useCallback(
        (...args) => {
            if (methods) methods.push([CRM_SILENT, ...args]);
        },
        [methods],
    );

    const open = useCallback(
        (...args) => {
            if (methods) methods.push([CRM_OPEN, ...args]);
        },
        [methods],
    );

    const close = useCallback(
        (...args) => {
            if (methods) methods.push([CRM_CLOSE, ...args]);
        },
        [methods],
    );

    const stop = useCallback(
        (...args) => {
            if (methods) methods.push([CRM_STOP, ...args]);
        },
        [methods],
    );

    return methods ? (
        <ChurnZeroContext.Provider
            value={{
                methods: {
                    setContact,
                    trackEvent,
                    setAttribute,
                    incrementAttribute,
                    setModule,
                    urltracking,
                    silent,
                    open,
                    close,
                    stop,
                },
                isReady,
                setCZAttributes,
            }}
        >
            {children}
        </ChurnZeroContext.Provider>
    ) : (
        <>{children}</>
    );
};


const useChurnZero = () => {
    const { methods, isReady, setCZAttributes } = useContext(ChurnZeroContext);

    return { methods, isReady, setCZAttributes };
};

export { ChurnZeroProvider, useChurnZero };
