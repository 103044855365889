import React, { useState, useEffect, useCallback, createContext, useMemo } from 'react';
import { useDecode } from '../../Decode';

const UserContext = createContext({});

const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [fetchedUser, setFetchedUser] = useState(false);

    const userId = useMemo(() => {
        const { id } = JSON.parse(
            atob(sessionStorage.getItem('curr-uat').split('.')[1], 'base64').toString('utf-8'),
        );
        return id;
    }, []);

    const { data: userData } = useDecode([`getUserById`, { userId }]);

    const getUserFromDecode = useCallback(async () => {
        if (!fetchedUser && userData?.payload) {
            setUser(userData.payload);
            setFetchedUser(true);
        }
    }, [fetchedUser, userData]);

    useEffect(() => {
        getUserFromDecode();
    }, [getUserFromDecode]);

    return (
        <UserContext.Provider value={{ ...user, fetchedUser }}>
            {fetchedUser ? children : null}
        </UserContext.Provider>
    );
};

export { UserProvider };
export default UserContext;
