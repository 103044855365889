import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import logo from './current_logo.svg';

export default function Layout({ title, children }) {
    return (
        <div className="layout">
            <div className="header">
                <a href="/">
                    <img src={logo} alt="Logo" className="logo" />
                </a>
                <Avatar
                    size="large"
                    icon={<UserOutlined />}
                    style={{ backgroundColor: '#1890ff' }}
                    className="avatar"
                />
            </div>
            <div className="content">{children}</div>
            <style jsx>
                {`
                    .header {
                        display: grid;
                        background-color: white;
                        margin-bottom: 20px;
                        grid-template-columns: 1fr 1fr;
                    }
                    .logo {
                        justify-self: start;
                    }
                    .avatar {
                        justify-self: end;
                    }
                    .layout {
                        max-width: 80%;
                        margin: 20px auto;
                    }
                    @media only screen and (max-width: 800px) {
                        .layout {
                            margin: 20px 20px;
                        }
                    }
                `}
            </style>
        </div>
    );
}

Layout.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};
