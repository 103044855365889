import React from 'react';
import PropTypes from 'prop-types';
import { useDecode } from '../../Decode';
import { Spin } from 'antd';
import {
    FacebookFilled,
    InstagramFilled,
    TwitterSquareFilled,
    YoutubeFilled,
    BankFilled,
    DollarCircleFilled
} from '@ant-design/icons';

export default function DashboardProgress({ clientId }) {
    const { data: progress, error: getProgressError } = useDecode([
        'getProgress',
        { clientId }
    ]);

    if (getProgressError) return <div>Failed to get get Social Links</div>;
    if (!progress) {
        return (
            <div className="loader">
                <Spin size="small" />
            </div>
        );
    }

    return (
        <div className="progress">
            {progress[0].facebook ? (
                <FacebookFilled style={{ color: '#52c41a' }} />
            ) : (
                <FacebookFilled style={{ color: '#fff1f0' }} />
            )}
            {progress[0].instagram ? (
                <InstagramFilled style={{ color: '#52c41a' }} />
            ) : (
                <InstagramFilled style={{ color: '#fff1f0' }} />
            )}
            {progress[0].twitter ? (
                <TwitterSquareFilled style={{ color: '#52c41a' }} />
            ) : (
                <TwitterSquareFilled style={{ color: '#fff1f0' }} />
            )}
            {progress[0].youtube ? (
                <YoutubeFilled style={{ color: '#52c41a' }} />
            ) : (
                <YoutubeFilled style={{ color: '#fff1f0' }} />
            )}
            {progress[0].banking ? (
                <BankFilled style={{ color: '#52c41a' }} />
            ) : (
                <BankFilled style={{ color: '#fff1f0' }} />
            )}
            {progress[0].media ? (
                <DollarCircleFilled style={{ color: '#52c41a' }} />
            ) : (
                <DollarCircleFilled style={{ color: '#fff1f0' }} />
            )}
            <style jsx>
                {`
                    .progress {
                        display: grid;
                        grid-template-columns: auto auto auto auto auto auto;
                    }
                `}
            </style>
        </div>
    );
}

DashboardProgress.propTypes = {
    clientId: PropTypes.number.isRequired
};
