export const IS_TEST = process.env.SERVER_ENV === 'test';
export const IS_DEV = process.env.SERVER_ENV === 'development';
export const IS_PROD = process.env.SERVER_ENV === 'production';
export const IS_STAGING = process.env.SERVER_ENV === 'stage';

export const IS_LOCAL = process.env.REACT_APP_ENV === 'local';

export const { APP_VERSION } = process.env;

export const HOSTNAME_IS_STAGING = window.location.hostname.includes('stage');
export const HOSTNAME_IS_DEV =
    window.location.hostname.includes('dev') || window.location.hostname.includes('localhost');
export const HOSTNAME_IS_TEST = window.location.hostname.includes('test');
