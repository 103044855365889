import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    Form,
    Input,
    Switch,
    Select
} from 'antd';

export default function NewUserModal({
    visible,
    onCreate,
    onCancel,
    permissions
}) {
    const { Option } = Select;
    const [form] = Form.useForm();

    return (
        <Modal
            visible={visible}
            title="Add New User"
            okText="Save new user"
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then(async (newUser) => {
                        await onCreate(newUser);
                        form.resetFields();
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form form={form} layout="vertical" name="newUserModal">
                <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[{ required: true, message: 'A first name is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="lastName"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'An email address is required' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true, message: 'A user role is required' }]}
                >
                    <Select>
                        <Option value="admin">
                            Admin
                        </Option>
                        <Option value="user">
                            User
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Permissions"
                    name="permissions"
                >
                    <Select mode="multiple" showArrow>
                        {permissions && permissions.map((p) => (
                            <Option key={p.columnName} value={p.columnName}>{fixSnakeCase(p.columnName)}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Super Admin" name="superAdmin">
                    <Switch />
                </Form.Item>
            </Form>
        </Modal>
    );
}

NewUserModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onCreate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired
};

const fixSnakeCase = (str) => str.split('_').map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`).join(' ');
