 
export class DecodeError extends Error {
    context
}

class InvalidParams extends DecodeError {
    constructor(message) {
        super(message);
        this.name = "InvalidParams";
    }
}

class UnexpectedError extends DecodeError {
    context;

    constructor(message, ctx) {
        super(message);
        this.name = "UnexpectedError";
        this.context = ctx;
    }
}

class NotFound extends DecodeError {
    constructor(message) {
        super(message);
        this.name = "NotFound";
    }
}

class NotAuthorized extends DecodeError {
    constructor(message) {
        super(message);
        this.name = "NotAuthorized";
    }
}

const errors = { InvalidParams, UnexpectedError, NotFound, NotAuthorized };

export default errors;
