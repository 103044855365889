import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import NewClient from './NewClient/NewClient';
import ClientEdit from './ClientEdit/ClientEdit';

const App = () => (
    <Router>
        <Switch>
            <Route exact path="/new">
                <NewClient />
            </Route>
            <Route exact path="/client/:clientId">
                <ClientEdit />
            </Route>
            <Route exact path="/">
                <Dashboard />
            </Route>
            <Route>{() => <>Not found</>}</Route>
        </Switch>
    </Router>
);

export default App;
