import React, { useEffect, useState } from 'react';
import { useDecode, useFetcher } from '../../Decode';
import moment from 'moment';
import useSWR, { mutate } from 'swr';
import Highlighter from 'react-highlight-words';
import { Button, Table, Spin, Input, Space, Modal } from 'antd';
import Icon, {
    PlusCircleOutlined,
    StopOutlined,
    AuditOutlined,
    SearchOutlined,
} from '@ant-design/icons';

import { Link, useHistory } from 'react-router-dom';
import DashboardProgress from './DashboardProgress';
import SocialLinks from './SocialLinks';
import Layout from '../Layout';

export default function Dashboard() {
    const history = useHistory();
    const fetcher = useFetcher();

    const [searchValue, setSearchValue] = useState('');
    const [searchColumn, setSearchColumn] = useState('');
    const [clients, setClients] = useState([]);
    useSWR('/getClients', fetcher);
    const { data: clientList, error: getClientsError } = useDecode('getClients', getClientsParser);
    const [selectedItem, setSelectedItem] = useState(null);
    const [inModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        setClients(clientList);
    }, [clientList]);

    if (getClientsError) return <div>Failed to get Clients</div>;
    if (!clients) {
        return (
            <div className='loader' style={loadStyle}>
                <Spin size='large' tip='Loading Clients' />
            </div>
        );
    }
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            /* eslint-disable react/prop-types */
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            /* eslint-enable react/prop-types */
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        render: (text) =>
            searchColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchValue]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    const showConfirmationWindow = (record) => {
        setSelectedItem(record);
        setIsModalVisible(true);
    };

    const handleConfirmDeactivation = async () => {
        setIsModalVisible(false);
        const clientId = selectedItem.id;
        await fetcher('deleteClientById', {
            clientId,
        });
        const updatedClients = await mutate('/getClients'); //new list after updating the deactivation for a client
        const updatedParsedData = getClientsParser(updatedClients);
        setClients([...updatedParsedData]); //changing the state
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setSelectedItem(null);
    };
    const columns = [
        {
            title: 'Account Name',
            dataIndex: 'accountName',
            key: 'accountName',
            ...getColumnSearchProps('accountName'),
            render: (text, record) => <Link to={`/client/${record.id}`}>{text}</Link>,
        },
        {
            title: 'Account Manager',
            dataIndex: 'accountManager',
            key: 'accountManager',
        },
        {
            title: 'Renewal Date',
            dataIndex: 'renewalDate',
            key: 'renewalDate',
            sorter: (a, b) => moment(a.renewalDate) - moment(b.renewalDate),
            render: (text) => (text === 'Invalid date' ? 'N/A' : text),
        },
        {
            title: 'Social Links',
            dataIndex: 'influencerId',
            key: 'influencerId',
            render: (text) => (
                <>
                    <SocialLinks influencerId={text} />{' '}
                </>
            ),
        },
        {
            title: 'Contract Links',
            dataIndex: 'contractLinks',
            key: 'contractLinks',
            render: (contractLinks) => (
                <div className='contract-links'>
                    {contractLinks?.contractLink ? (
                        <a
                            href={`${contractLinks?.contractLink}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <AuditOutlined style={{ color: '#faad14', fontSize: '1.7em' }} />
                        </a>
                    ) : (
                        <AuditOutlined style={{ color: '#d4d4d4', fontSize: '1.7em' }} />
                    )}
                    {contractLinks?.hubSpot ? (
                        <a
                            href={`${contractLinks?.hubSpot}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <HubspotIcon style={{ fill: '#ff7a59', fontSize: '1.7em' }} />
                        </a>
                    ) : (
                        <HubspotIcon style={{ fill: '#d4d4d4', fontSize: '1.7em' }} />
                    )}
                </div>
            ),
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (text) => (
                <>
                    <DashboardProgress clientId={text} />{' '}
                </>
            ),
        },
        {
            title: '',
            key: 'deactivation',
            render: (text, record) => (
                <Button
                    danger
                    icon={<StopOutlined />}
                    size='small'
                    shape='round'
                    onClick={() => showConfirmationWindow(record)}
                >
                    {' '}
                    Deactivate
                </Button>
            ),
        },
    ];

    const handleNew = () => {
        history.push('/new');
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchValue(selectedKeys[0]);
        setSearchColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchValue('');
    };

    return (
        <Layout title='Client Dashboard'>
            <div className='dashboard'>
                <Button
                    type='primary'
                    shape='round'
                    icon={<PlusCircleOutlined />}
                    size='large'
                    onClick={handleNew}
                >
                    Create New Account
                </Button>
                <Table dataSource={clients} columns={columns} />
                <Modal
                    style={{ top: 270 }}
                    className='confirm-modal'
                    title='Confirm Deactivation'
                    visible={inModalVisible}
                    onCancel={handleCancel}
                    footer={[
                        <Button key='close' onClick={handleCancel}>
                            Back
                        </Button>,
                        <Button
                            key='delete'
                            type='primary'
                            danger
                            onClick={handleConfirmDeactivation}
                        >
                            Confirm
                        </Button>,
                    ]}
                >
                    {' '}
                    Are you sure about deactivating {selectedItem?.accountName} account?
                </Modal>
            </div>
            <style jsx>
                {`
                    .dashboard {
                        color: #fff;
                        display: grid;
                        grid-template-rows: auto auto;
                        grid-row-gap: 15px;
                    }
                    .ant-btn-round.ant-btn-lg {
                        width: 250px;
                        justify-self: end;
                    }
                    .social-links {
                        display: grid;
                        justify-content: start;
                        grid-template-columns: auto auto auto auto;
                        grid-column-gap: 10px;
                    }
                    .contract-links {
                        display: grid;
                        justify-content: start;
                        grid-template-columns: auto auto;
                        grid-column-gap: 10px;
                    }
                    .loader {
                        width: 100%,
                        height: 100vh,
                        display: grid,
                        alignItems: center,
                    }
                `}
            </style>
        </Layout>
    );
}

const loadStyle = {
    width: '100%',
    height: '100vh',
    display: 'grid',
    alignItems: 'center',
};

let getClientsParser = (results) =>
    results.map((c) => ({
        id: c.id,
        influencerId: c.influencerId,
        accountName: c.accountName,
        accountManager: c.firstName
            ? `${c.firstName ? `${c.firstName}` : ''}${c.lastName ? ` ${c.lastName}` : ''}`
            : 'N/A',
        renewalDate: moment(c.renewalDate).format('MMM D, YYYY'),
        socialLinks: {},
        contractLinks: {
            contractLink: c.contractLink,
            hubSpot: c.crmLink,
        },
        progress: c.id,
    }));

const HubspotSvg = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        height='1em'
        viewBox='6.20856283 .64498824 244.26943717 251.24701176'
        width='1em'
    >
        <path
            d='m191.385 85.694v-29.506a22.722 22.722 0 0 0 13.101-20.48v-.677c0-12.549-10.173-22.722-22.721-22.722h-.678c-12.549 0-22.722 10.173-22.722 22.722v.677a22.722 22.722 0 0 0 13.101 20.48v29.506a64.342 64.342 0 0 0 -30.594 13.47l-80.922-63.03c.577-2.083.878-4.225.912-6.375a25.6 25.6 0 1 0 -25.633 25.55 25.323 25.323 0 0 0 12.607-3.43l79.685 62.007c-14.65 22.131-14.258 50.974.987 72.7l-24.236 24.243c-1.96-.626-4-.959-6.057-.987-11.607.01-21.01 9.423-21.007 21.03.003 11.606 9.412 21.014 21.018 21.017 11.607.003 21.02-9.4 21.03-21.007a20.747 20.747 0 0 0 -.988-6.056l23.976-23.985c21.423 16.492 50.846 17.913 73.759 3.562 22.912-14.352 34.475-41.446 28.985-67.918-5.49-26.473-26.873-46.734-53.603-50.792m-9.938 97.044a33.17 33.17 0 1 1 0-66.316c17.85.625 32 15.272 32.01 33.134.008 17.86-14.127 32.522-31.977 33.165'
            fill='inherit'
        />
    </svg>
);

/* eslint-disable react/jsx-props-no-spreading */
const HubspotIcon = (props) => <Icon component={HubspotSvg} {...props} />;
/* eslint-enable react/jsx-props-no-spreading */
