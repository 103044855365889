import React, { useState, useContext } from 'react';
import { useDecode, useFetcher } from '../../Decode';
import moment from 'moment';
import {
    Form,
    Input,
    Button,
    Divider,
    DatePicker,
    Collapse,
    Switch,
    InputNumber,
    Select,
    Spin,
    notification
} from 'antd';
import {
    PlusCircleOutlined,
    StopOutlined,
    ExclamationCircleOutlined,
    SmileOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Layout from '../Layout';
import { HUB_IMPLEMENTATION } from '../constants/userEvents';
import useEventTracking from '../hooks/useEventTracking';
import { useChurnZero } from '../context/churnZeroContext';
import UserContext from '../context/userContext';

export default function New() {
    const { Panel } = Collapse;
    const { Option } = Select;
    const history = useHistory();
    const fetcher = useFetcher();
    const { trackEvent } = useEventTracking();
    const { setCZAttributes } = useChurnZero();
    const { firstName, lastName, email } = useContext(UserContext);

    const [attemptingSave, setAttemptingSave] = useState(false);

    const createClient = async (v) => {
        setAttemptingSave(true);
        try {
            const links = {};
            if (v.youtubeLink) links.youtubeUrl = v.youtubeLink;
            if (v.facebookLink) links.fbUrl = v.facebookLink;
            if (v.instagramLink) links.instaUrl = v.instagramLink;
            if (v.twitterLink) links.twitterUrl = v.twitterLink;
            const resp = await fetcher('createInfluencer', {
                body: {
                    influencer: {
                        name: v.name,
                        source: 'C4',
                        reviewFlag: 'client',
                        ...links
                    }
                }
            });
            let influencerId;
            if (resp.success) {
                influencerId = resp.payload.influencer.id;
            } else {
                const rawInfluencerId = await fetcher('getInfluencerId', { name: v.name });
                influencerId = rawInfluencerId[0].id;
            }
            const body = {
                accountName: v.name,
                influencerId,
                accountManagerId: v.manager || null,
                renewalDate: v.renewalDate ? moment(v.renewalDate).format('YYYY-MM-DD') : null,
                contractLink: v.contractLink || null,
                crmLink: v.hubspotLink || null,
                storeType: v.ecommerce || null,
                dealType: v.dealType || null,
                phoneNumber: v.accountPhoneNumber || null,
                billingContact: v.billingContact || null,
                billingEmail: v.billingEmail || null,
                billingPhone: v.billingPhone || null,
                paymentVolume: v.paymentVolumeEstimate ? Number(Number(v.paymentVolumeEstimate).toFixed(2)) * 100 : null,
                checkoutPixelActive: v.pixelIntegrated ? 1 : 0,
                hasHub: v.ambassadorHub ? 1 : 0,
                requireApprovals: v.approvals ? 1 : 0,
                hubName: v.ambassadorName || null,
                hubDomain: v.ambassadorDomain || null,
                saasFlatFee: v.flatFee ? Number(Number(v.flatFee).toFixed(2)) * 100 : null,
                saasPercentFee: v.percentOfSales ? (Number(Number(v.percentOfSales).toFixed(1)) / 100).toFixed(3) : null,
                servicesFee: v.monthlyServiceFee ? Number(Number(v.monthlyServiceFee).toFixed(2)) * 100 : null,
                paymentsDebitFee: v.feePercentOnDebits ? (Number(Number(v.feePercentOnDebits).toFixed(2)) / 100).toFixed(4) : null,
                paymentsCreditFlatFee: v.flatFeeOnCredits ? Number(Number(v.flatFeeOnCredits).toFixed(2)) * 100 : null,
                paymentsCreditPercentFee: v.feePercentOnCredits ? (Number(Number(v.feePercentOnCredits).toFixed(2)) / 100).toFixed(4) : null,
                taxReportingFee: v.taxFee ? Number(Number(v.taxFee).toFixed(2)) * 100 : null,
                equityAmount: v.equityAmount ? (Number(Number(v.equityAmount).toFixed(2)) / 100).toFixed(4) : null,
                statementCloseDay: v.statementCloseDate || null,
                paymentDueDay: v.paymentDueDate || null,
                ambassadorSalesTracking: v.ambassadorSalesTracking || null,
                ambassadorTrackingTags: v.ambassadorTrackingTags || null,
                defaultFlatRate: v.ambassadorFlatRate ? Number(Number(v.ambassadorFlatRate).toFixed(2)) * 100 : null,
                defaultPercentOfSales: v.ambassadorSalesPercent ? (Number(Number(v.ambassadorSalesPercent).toFixed(2)) / 100).toFixed(4) : null
            };
            const result = await fetcher('createClient', { body });
            setAttemptingSave(false);
            if (result.success) {
                const { name, salesforceId, id: accountId } = result.payload;
                if (salesforceId && accountId) {
                    setCZAttributes({ firstName, lastName, accountName: name, email, salesforceId, accountId });
                    if (v.ambassadorHub) {
                        trackEvent(HUB_IMPLEMENTATION.HUB_SETUP_COMPLETED);
                    }
                    if (v.pixelIntegrated) {
                        trackEvent(HUB_IMPLEMENTATION.PIXEL_TRACKING_CONNECTED);
                    }
                    if (v.ambassadorTrackingTags) {
                        trackEvent(HUB_IMPLEMENTATION.BRAND_KEYWORDS_ADDED);
                    }
                }
                return result.payload;
            }
            throw new Error('client create/network failed');
        } catch (err) {
            console.log(err);
            setAttemptingSave(false);
            return null;
        }
    };

    const handleSave = async (newClient) => {
        try {
            let newId;
            const createdClient = await createClient(newClient);
            if (createdClient) newId = createdClient.id;
            else {
                const rawNewId = await fetcher('getClientIdByName', { name: newClient.name });
                if (rawNewId && rawNewId[0]) newId = rawNewId[0].id;
                else throw new Error('client create failed');
            }
            fetcher('setOnboardingSessionToken', { body: { clientId: newId } });
            history.push(`/client/${newId}`);
            notification.open({
                message: 'Saved Successfully!',
                description: `${newClient.name} has been successfully saved!`,
                icon: <SmileOutlined style={{ color: '#52c41a' }} />
            });
        } catch (err) {
            console.log(err);
            notification.open({
                message: 'Not Saved!',
                description: 'Please notify the dev team about this error.',
                icon: <ExclamationCircleOutlined style={{ color: '#f5222d' }} />
            });
        }
    };

    const handleCancel = () => {
        history.push('/');
    };

    const { data: accountManagers, error: getAccountManagersError } = useDecode(
        'getAccountManagers',
        getAccountManagersParser
    );

    if (getAccountManagersError) return <div>{'Failed to get AM\'s'}</div>;
    if (!accountManagers) {
        return (
            <div className="loader" style={loadStyle}>
                <Spin size="large" tip="Laying the pipes..." />
            </div>
        );
    }

    return (
        <Layout title="New Client">
            <div>
                <Form
                    name="account"
                    layout="horizontal"
                    labelAlign="right"
                    onFinish={handleSave}
                >
                    <div className="account-details">
                        <div className="account-header">
                            <div className="account-name-am">
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'An account name is required!'
                                        }
                                    ]}
                                >
                                    <Input placeholder="Account Name *" className="account-name" />
                                </Form.Item>
                                <Form.Item
                                    label="AM"
                                    name="manager"
                                    className="account-manager-input"
                                >
                                    <Select options={accountManagers} />
                                </Form.Item>
                            </div>
                            <div className="form-buttons">
                                <Form.Item>
                                    <Button
                                        danger
                                        icon={<StopOutlined />}
                                        size="large"
                                        shape="round"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        icon={<PlusCircleOutlined />}
                                        size="large"
                                        shape="round"
                                        htmlType="submit"
                                        loading={attemptingSave}
                                    >
                                        Create Client
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="account-info">
                            <Form.Item label="Renewal Date" name="renewalDate">
                                <DatePicker placeholder="2021-01-01" />
                            </Form.Item>
                            <Form.Item label="Contract Link" name="contractLink">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Hubspot Link" name="hubspotLink">
                                <Input />
                            </Form.Item>
                            <Form.Item label="eCommerce" name="ecommerce">
                                <Select>
                                    <Option value="shopify">Shopify</Option>
                                    <Option value="woocommerce">WooCommerce</Option>
                                    <Option value="bigcommerce">BigCommerce</Option>
                                    <Option value="other">Other</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Phone Number" name="accountPhoneNumber">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Billing Contact" name="billingContact">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Billing Email" name="billingEmail">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Billing Phone" name="billingPhone">
                                <Input />
                            </Form.Item>
                        </div>
                        <Divider />
                        <div className="social-links">
                            <Form.Item label="Facebook Link" name="facebookLink">
                                <Input />
                            </Form.Item>
                            <Form.Item label="YouTube Link" name="youtubeLink">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Instagram Link" name="instagramLink">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Twitter Link" name="twitterLink">
                                <Input />
                            </Form.Item>
                        </div>
                        <Collapse ghost bordered={false} defaultActiveKey={['1', '2', '3']}>
                            <Panel header="Additional Client Info" key="2">
                                <div className="additional-client-info">
                                    <div>
                                        <Form.Item
                                            label="Payment Approvals Required"
                                            name="approvals"
                                        >
                                            <Switch />
                                        </Form.Item>
                                        <Form.Item
                                            label="Payment Volume Estimate"
                                            name="paymentVolumeEstimate"
                                        >
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Pixel Integrated" name="pixelIntegrated">
                                            <Switch />
                                        </Form.Item>
                                        <Form.Item label="Ambassador Hub" name="ambassadorHub">
                                            <Switch />
                                        </Form.Item>
                                        <Form.Item label="Ambassador Name" name="ambassadorName">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Ambassador Domain"
                                            name="ambassadorDomain"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="Billing and Fee Schedule" key="3">
                                <div className="billing-and-fee">
                                    <div>
                                        <h3>SaaS Fee Schedule</h3>
                                        <Form.Item label="Deal Type" name="dealType">
                                            <Select allowClear>
                                                <Option value="flat fee">
                                                    Flat fee
                                                </Option>
                                                <Option value="flat fee with performance">
                                                    Flat fee with performance
                                                </Option>
                                                <Option value="performance with minimum">
                                                    Performance with minimum
                                                </Option>
                                                <Option value="performance with minimum/equity">
                                                    Performance with minimum/equity
                                                </Option>
                                                <Option value="performance with no minimum">
                                                    Performance with no minimum
                                                </Option>
                                                <Option value="performance with no minimum/equity">
                                                    Performance with no minimum/equity
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Flat Fee" name="flatFee">
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                        <Form.Item label="% of Sales" name="percentOfSales">
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.01}
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Equity Amount" name="equityAmount">
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.01}
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <h3>Billing Dates</h3>
                                        <Form.Item label="Statement Close Date" name="statementCloseDate">
                                            <InputNumber min={1} max={30} />
                                        </Form.Item>
                                        <Form.Item label="Payment Due Date" name="paymentDueDate">
                                            <InputNumber min={1} max={30} />
                                        </Form.Item>
                                        <h3>Service Fee Schedule</h3>
                                        <Form.Item label="Monthly Fee" name="monthlyServiceFee">
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <h3>Payment Platform Fee Schedule</h3>
                                        <Form.Item
                                            label="Fee % on Debits"
                                            name="feePercentOnDebits"
                                        >
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.01}
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Flat Fee on Credits"
                                            name="flatFeeOnCredits"
                                        >
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Fee % on Credits"
                                            name="feePercentOnCredits"
                                        >
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.01}
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                        <Form.Item label="1099 Fee" name="taxFee">
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <h3>Ambassador Fee Schedule</h3>
                                        <Form.Item
                                            label="Default Flat Rate"
                                            name="ambassadorFlatRate"
                                        >
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Default % of Sales"
                                            name="ambassadorSalesPercent"
                                        >
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.01}
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Ambassador Sales Tracking"
                                            name="ambassadorSalesTracking"
                                        >
                                            <Select>
                                                <Option value="codes & clicks">Codes and Clicks</Option>
                                                <Option value="clicks only">Clicks only</Option>
                                                <Option value="codes only">Codes only</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Ambassador Post Tracking Tags"
                                            name="ambassadorTrackingTags"
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                </Form>
            </div>
            <style jsx>
                {`
                    .ant-form-item {
                        margin-bottom: 12px;
                    }
                    .ant-form-item-label {
                        min-width: 120px;
                    }
                    .additional-client-info .ant-form-item-label {
                        min-width: 180px;
                    }
                    .additional-client-info .ant-input {
                        width: 50%;
                    }
                    .ant-input {
                        width: 80%;
                    }
                    .ant-input-number {
                        width: 50%;
                        min-width: 120px;
                    }
                    .account-details {
                        width: 100%;
                        display: grid;
                        grid-template-rows: auto auto;
                    }
                    .account-header {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-row-gap: 24px;
                        margin-bottom: 15px;
                    }
                    .account-name {
                        font-size: 2em;
                        width: 130%;
                    }
                    .account-manager-input {
                        width: 70%;
                    }
                    .form-buttons {
                        justify-self: end;
                        display: grid;
                        grid-template-columns: auto auto;
                        grid-column-gap: 24px;
                        justify-items: end;
                        padding-top: 8px;
                    }
                    .account-info {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: auto auto auto auto;
                        grid-column-gap: 24px;
                        grid-auto-flow: column;
                    }
                    .social-links {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: auto auto;
                        grid-column-gap: 24px;
                        grid-auto-flow: column;
                        margin-bottom: 15px;
                    }
                    .ant-collapse-borderless {
                        background-color: white;
                    }
                    .ant-collapse-header {
                        font-size: 2em;
                    }
                    .additional-client-info {
                        display: grid;
                        grid-template-columns: 1fr 30%;
                        grid-column-gap: 24px;
                        margin-bottom: 15px;
                    }
                    .billing-and-fee {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 24px;
                    }
                `}
            </style>
        </Layout>
    );
}

const loadStyle = {
    width: '100%',
    height: '100vh',
    display: 'grid',
    alignItems: 'center'
};

const getAccountManagersParser = (results) => results.map((c) => ({ label: `${c.firstName} ${c.lastName}`, value: c.id }));
