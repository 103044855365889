export const CHURN_ZERO_EVENT_TYPE = {
    CRM_EVENT_TRACK: 'trackEvent',
    CRM_APP_KEY: 'setAppKey',
    CRM_SET_CONTACT: 'setContact',
    CRM_SET_MODULE: 'setModule',
    CRM_SET_ATTRIBUTE: 'setAttribute',
    CRM_INCREMENT_ATTRIBUTE: 'incrementAttribute',
    CRM_URL_TRACKING: 'urltracking',
    CRM_SILENT: 'silent',
    CRM_OPEN: 'open',
    CRM_CLOSE: 'close',
    CRM_STOP: 'stop',
};

export const CHURN_ZERO_SDK_URL = {
    DEV: 'https://current-dev.us1app.churnzero.net/churnzero.js',
    PROD: 'https://current.us1app.churnzero.net/churnzero.js',
};

export const CHURN_ZERO_APP_KEY = {
    DEV: '1!mUx7pdruldrjBwuO1tN4xpt5TvJuw35HWsqRXxxhyHkt127C',
    PROD: '1!CIoVlEAHOs2m9bCVKR41M6-3IPpKX4CjMFVZzWbq4iEt127D',
};
