import React, { useContext, useState } from 'react';
import { useDecode, useFetcher } from '../../Decode';
import moment from 'moment';
import {
    Form,
    Input,
    Button,
    Divider,
    DatePicker,
    Collapse,
    Switch,
    InputNumber,
    Select,
    Spin,
    notification
} from 'antd';
import {
    SaveOutlined,
    StopOutlined,
    SmileOutlined,
    ExclamationCircleOutlined,
    CopyOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../Layout';
import Users from './Users';
import Groups from './Groups';
import Status from './Status';
import { HUB_IMPLEMENTATION } from '../constants/userEvents';
import useEventTracking from '../hooks/useEventTracking';
import { useChurnZero } from '../context/churnZeroContext';
import UserContext from '../context/userContext';

export default function Edit() {
    const { Panel } = Collapse;
    const { Option } = Select;
    const { clientId } = useParams();
    const history = useHistory();
    const fetcher = useFetcher();
    const { trackEvent } = useEventTracking();
    const { setCZAttributes } = useChurnZero();
    const { firstName, lastName, email } = useContext(UserContext);

    const [allowSave, setAllowSave] = useState(false);
    const [attemptingSave, setAttemptingSave] = useState(false);

    const { data: client, error: getClientError } = useDecode(
        ['getClientById', { id: clientId }],
        getClientParser
    );

    const { data: links, error: getLinksError } = useDecode(
        ['getClientLinksById', { clientId }],
        getLinksParser
    );

    const { data: accountManagers, error: getAccountManagersError } = useDecode(
        'getAccountManagers',
        getAccountManagersParser
    );

    const { data: onboardingToken, error: getOnboardingTokenError } = useDecode(
        ['getOnboardingToken', { clientId }],
        getOnboardingTokenParser
    );

    const completeClient = { ...client, ...links, onboardingToken };

    const [originalValues] = React.useState({ completeClient });

    const updateClient = async (v) => {
        setAttemptingSave(true);
        try {
            const body = {
                accountName: v.name,
                accountManagerId: v.manager,
                renewalDate: v.renewalDate ? moment(v.renewalDate).format('YYYY-MM-DD') : null,
                contractLink: v.contractLink,
                crmLink: v.hubspotLink,
                storeType: v.ecommerce,
                phoneNumber: v.accountPhoneNumber,
                billingContact: v.billingContact,
                billingEmail: v.billingEmail,
                billingPhone: v.billingPhone,
                paymentVolume: v.paymentVolumeEstimate ? Number(Number(v.paymentVolumeEstimate).toFixed(2)) * 100 : null,
                checkoutPixelActive: v.pixelIntegrated ? 1 : 0,
                hasHub: v.ambassadorHub ? 1 : 0,
                hubName: v.ambassadorName,
                requireApprovals: v.approvals ? 1 : 0,
                hubDomain: v.ambassadorDomain,
                saasFlatFee: v.flatFee ? Number(Number(v.flatFee).toFixed(2)) * 100 : null,
                saasPercentFee: v.percentOfSales ? (Number(Number(v.percentOfSales).toFixed(1)) / 100).toFixed(3) : null,
                equityAmount: v.equityAmount ? (Number(Number(v.equityAmount).toFixed(2)) / 100).toFixed(4) : null,
                statementCloseDay: v.statementCloseDate,
                dealType: v.dealType,
                servicesFee: v.monthlyServiceFee ? Number(Number(v.monthlyServiceFee).toFixed(2)) * 100 : null,
                paymentDueDay: v.paymentDueDate,
                paymentsDebitFee: v.feePercentOnDebits ? (Number(Number(v.feePercentOnDebits).toFixed(2)) / 100).toFixed(4) : null,
                paymentsCreditFlatFee: v.flatFeeOnCredits ? Number(Number(v.flatFeeOnCredits).toFixed(2)) * 100 : null,
                paymentsCreditPercentFee: v.feePercentOnCredits ? (Number(Number(v.feePercentOnCredits).toFixed(2)) / 100).toFixed(4) : null,
                taxReportingFee: v.taxFee ? Number(Number(v.taxFee).toFixed(2)) * 100 : null,
                ambassadorSalesTracking: v.ambassadorSalesTracking,
                ambassadorTrackingTags: v.ambassadorTrackingTags,
                defaultFlatRate: v.ambassadorFlatRate ? Number(Number(v.ambassadorFlatRate).toFixed(2)) * 100 : null,
                defaultPercentOfSales: v.ambassadorSalesPercent ? (Number(Number(v.ambassadorSalesPercent).toFixed(2)) / 100).toFixed(4) : null,
                id: clientId,
                salesforceId: v.salesforceId?.trim(),
            };
            const rawInfluencerId = await fetcher('getInfluencerIdByClientId', {
                clientId
            });
            const influencerId = rawInfluencerId[0].id;
            const promiseArr = [
                fetcher('updateInfluencer', {
                    name: v.name,
                    influencerId
                }),
                fetcher('updateClientApi', { body })
            ];

            if (v.facebookLink) {
                promiseArr.push(
                    fetcher('updateNetworkLink', {
                        networkName: 'facebook',
                        networkURL: v.facebookLink,
                        influencerId
                    })
                );
            }
            if (v.youtubeLink) {
                promiseArr.push(
                    fetcher('updateNetworkLink', {
                        networkName: 'youtube',
                        networkURL: v.youtubeLink,
                        influencerId
                    })
                );
            }
            if (v.instagramLink) {
                promiseArr.push(
                    fetcher('updateNetworkLink', {
                        networkName: 'instagram',
                        networkURL: v.instagramLink,
                        influencerId
                    })
                );
            }
            if (v.twitterLink) {
                promiseArr.push(
                    fetcher('updateNetworkLink', {
                        networkName: 'twitter',
                        networkURL: v.twitterLink,
                        influencerId
                    })
                );
            }
            await Promise.all(promiseArr);
            setAttemptingSave(false);

            const salesforceId = v.salesforceId?.trim();
            setCZAttributes({ firstName, lastName, accountName: v.name, email, salesforceId, accountId: clientId });

            if (!!originalValues.hasHub !== !!v.ambassadorHub && v.ambassadorHub) {
                trackEvent(HUB_IMPLEMENTATION.HUB_SETUP_COMPLETED);
            }
            if (!!originalValues.pixelIntegrated !== !!v.pixelIntegrated && v.pixelIntegrated) {
                trackEvent(HUB_IMPLEMENTATION.PIXEL_TRACKING_CONNECTED);
            }
            if (originalValues.ambassadorTrackingTags !== v.ambassadorTrackingTags && v.ambassadorTrackingTags) {
                trackEvent(HUB_IMPLEMENTATION.BRAND_KEYWORDS_ADDED);
            }

            return true;
        } catch (err) {
            console.log(err);
            setAttemptingSave(false);
            return false;
        }
    };

    const handleCancel = () => {
        history.push('/');
    };

    const handleChange = () => { // changedValue, allValues
        setAllowSave(true);
        // revisedClient = allValues;
    };

    const handleOnboardingLinkGenerate = async () => {
        try {
            await fetcher('setOnboardingSessionToken', { body: { clientId } });
            return true;
        } catch (err) {
            console.log(err);
        }
    };

    const getNewCustomerSalesReport = async () => {
        try {
            const { start_date, end_date } = firstAndLastDayOfLASTMonth();
            await fetcher('getNewCustomerSalesReport', { 
                start_date: start_date, 
                end_date: end_date,
                client_id: clientId});
            return true;
        } catch (err) {
            console.log(err);
        }
    };

    function firstAndLastDayOfLASTMonth() {
        const start_date = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
        const end_date = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
        return { start_date, end_date };
    }

    const handleOnboardingLinkCopy = (e) => {
        const { name } = e.currentTarget;
        let saved;
        const elem = document.getElementById(name);
        if (elem) {
            elem.focus();
            elem.select();
            saved = document.execCommand('copy');
        }
        if (saved) {
            notification.open({
                message: 'Link Copied!',
                icon: <SmileOutlined style={{ color: '#52c41a' }} />
            });
        }
    };

    const handleSave = async (revisedClient) => {
        try {
            const result = await updateClient(revisedClient);
            if (!result) throw new Error('save failed');
            setAttemptingSave(false);
            setAllowSave(false);
            notification.open({
                message: 'Saved Successfully!',
                description: `${revisedClient.name} has been successfully saved!`,
                icon: <SmileOutlined style={{ color: '#52c41a' }} />
            });
        } catch (err) {
            console.log(err);
            notification.open({
                message: 'Not Saved!',
                description: 'We encountered an error saving!',
                icon: <ExclamationCircleOutlined style={{ color: '#f5222d' }} />
            });
        }
    };

    if (getAccountManagersError || getClientError || getLinksError) return <div>Failed to get data</div>;
    if (!accountManagers || !client || !links) {
        return (
            <div className="loader" style={loadStyle}>
                <Spin size="large" tip="Loading Client" />
            </div>
        );
    }
    
    return (
        <Layout title="Edit Client">
            <div>
                <Form
                    name="account"
                    layout="horizontal"
                    labelAlign="right"
                    initialValues={completeClient}
                    onValuesChange={handleChange}
                    onFinish={handleSave}
                >
                    <div className="account-details">
                        <div className="account-header">
                            <div className="account-name-am">
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'An account name is required!'
                                        }
                                    ]}
                                >
                                    <Input placeholder="Account Name" className="account-name" />
                                </Form.Item>
                                    <Form.Item
                                        label="AM"
                                        name="manager"
                                        className="account-manager-input"
                                    >
                                        <Select options={accountManagers} />
                                    </Form.Item>
                            </div>
                            <div className="form-buttons">
                                <Form.Item>
                                    <Button
                                        danger
                                        icon={<StopOutlined />}
                                        size="large"
                                        shape="round"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        icon={<SaveOutlined />}
                                        size="large"
                                        shape="round"
                                        htmlType="submit"
                                        loading={attemptingSave}
                                        disabled={!allowSave}
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="onboarding-link-container">
                            <Form.Item
                                label="Onboarding Link"
                                name="onboardingToken"
                                readOnly
                            >
                                <Input fullWidth />
                            </Form.Item>
                            <div className="onboarding-actions">
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        icon={<CopyOutlined />}
                                        size="small"
                                        shape="round"
                                        onClick={handleOnboardingLinkCopy}
                                        name="account_onboardingToken"
                                        disabled={!onboardingToken}
                                    >
                                        Copy
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        icon={<PlusCircleOutlined />}
                                        size="small"
                                        shape="round"
                                        onClick={handleOnboardingLinkGenerate}
                                        disabled={!getOnboardingTokenError}
                                    >
                                        Generate Link
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="account-info">
                            <Form.Item label="Renewal Date" name="renewalDate">
                                <DatePicker />
                            </Form.Item>
                            <Form.Item label="Contract Link" name="contractLink">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Hubspot Link" name="hubspotLink">
                                <Input />
                            </Form.Item>
                            <Form.Item label="eCommerce" name="ecommerce">
                                <Select>
                                    <Option value="shopify">Shopify</Option>
                                    <Option value="woocommerce">WooCommerce</Option>
                                    <Option value="bigcommerce">BigCommerce</Option>
                                    <Option value="other">Other</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Phone Number" name="accountPhoneNumber">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Billing Contact" name="billingContact">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Billing Email" name="billingEmail">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Billing Phone" name="billingPhone">
                                <Input />
                            </Form.Item>
                        </div>
                        <Divider />
                        <div className="social-links">
                            <Form.Item label="Facebook Link" name="facebookLink">
                                <Input />
                            </Form.Item>
                            <Form.Item label="YouTube Link" name="youtubeLink">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Instagram Link" name="instagramLink">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Twitter Link" name="twitterLink">
                                <Input />
                            </Form.Item>
                        </div>
                        <Divider />
                        <Collapse ghost bordered={false} defaultActiveKey={['1', '2', '3', '4']}>
                            <Panel header="Users" key="1">
                                <Users clientId={clientId} name={client.name} />
                            </Panel>
                            <Panel header="Additional Client Info" key="2">
                                <div className="additional-client-info">
                                    <div>
                                        <Form.Item
                                            label="Payment Approvals Required"
                                            name="approvals"
                                        >
                                            <Switch />
                                        </Form.Item>
                                        <Form.Item
                                            label="Payment Volume Estimate"
                                            name="paymentVolumeEstimate"
                                        >
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Pixel Integrated" name="pixelIntegrated">
                                            <Switch defaultChecked={completeClient.pixelIntegrated} />
                                        </Form.Item>
                                        <Form.Item label="Ambassador Hub" name="ambassadorHub">
                                            <Switch defaultChecked={completeClient.ambassadorHub} />
                                        </Form.Item>
                                        <Form.Item label="Hub Setup Completed" name="hubCompleted">
                                            <Switch defaultChecked={completeClient.ambassadorHub} />
                                        </Form.Item>
                                        <Form.Item label="Ambassador Name" name="ambassadorName">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Ambassador Domain"
                                            name="ambassadorDomain"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name="salesforceId" label="Salesforce ID">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <Status clientId={clientId} />
                                </div>
                                <Groups clientId={clientId} />
                            </Panel>
                            <Panel header="Billing and Fee Schedule" key="3">
                                <div className="billing-and-fee">
                                    <div>
                                        <h3>SaaS Fee Schedule</h3>
                                        <Form.Item label="Deal Type" name="dealType">
                                            <Select allowClear>
                                                <Option value="flat fee">Flat fee</Option>
                                                <Option value="flat fee with performance">Flat fee with performance</Option>
                                                <Option value="performance with minimum">
                                                    Performance with minimum
                                                </Option>
                                                <Option value="performance with minimum/equity">
                                                    Performance with minimum/equity
                                                </Option>
                                                <Option value="performance with no minimum">
                                                    Performance with no minimum
                                                </Option>
                                                <Option value="performance with no minimum/equity">
                                                    Performance with no minimum/equity
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Flat Fee" name="flatFee">
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                        <Form.Item label="% of Sales" name="percentOfSales">
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.1}
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Equity Amount" name="equityAmount">
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.01}
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <h3>Important Dates</h3>
                                        <Form.Item label="Statement Close Date" name="statementCloseDate">
                                            <InputNumber min={1} max={25} />
                                        </Form.Item>
                                        <Form.Item label="Pending Days (review period)" name="paymentDueDate">
                                            <InputNumber min={1} max={7} />
                                        </Form.Item>
                                        <h3>Service Fee Schedule</h3>
                                        <Form.Item label="Monthly Fee" name="monthlyServiceFee">
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <h3>Payment Platform Fee Schedule</h3>
                                        <Form.Item
                                            label="Fee % on Debits"
                                            name="feePercentOnDebits"
                                        >
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.01}
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Flat Fee on Credits"
                                            name="flatFeeOnCredits"
                                        >
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Fee % on Credits"
                                            name="feePercentOnCredits"
                                        >
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.01}
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                        <Form.Item label="1099 Fee" name="taxFee">
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <h3>Ambassador Fee Schedule</h3>
                                        <Form.Item
                                            label="Default Flat Rate"
                                            name="ambassadorFlatRate"
                                        >
                                            <InputNumber
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                step={0.01}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Default % of Sales"
                                            name="ambassadorSalesPercent"
                                        >
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                step={0.01}
                                                formatter={(value) => `${value}%`}
                                                parser={(value) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Ambassador Sales Tracking"
                                            name="ambassadorSalesTracking"
                                        >
                                            <Select>
                                                <Option value="codes & clicks">Codes and Clicks</Option>
                                                <Option value="clicks only">Clicks only</Option>
                                                <Option value="codes only">Codes only</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="Ambassador Post Tracking Tags"
                                            name="ambassadorTrackingTags"
                                        >
                                            <Input.TextArea autoSize />
                                        </Form.Item>
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="Make Things" key="1">
                                <div className="form-buttons">
                                    <Form.Item>
                                        <Button
                                            size="small"
                                            shape="round"
                                            onClick={getNewCustomerSalesReport}
                                        >
                                            Generate Previous Month's Sales Report
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                </Form>
            </div>
            <style jsx>
                {`
                .ant-form-item {
                    margin-bottom: 12px;
                }
                .ant-form-item-label {
                    min-width: 120px;
                }
                .additional-client-info .ant-form-item-label {
                    min-width: 180px;
                }
                .additional-client-info .ant-input {
                    width: 50%;
                }
                .ant-input {
                    width: 80%;
                }
                .ant-input-number {
                    width: 50%;
                    min-width: 120px;
                }
                .account-details {
                    width: 100%;
                    display: grid;
                    grid-template-rows: auto auto;
                }
                .onboarding-link-container {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: baseline;
                }
                .onboarding-link-container > .ant-form-item:first-child {
                    width: calc(100% - 232px);
                }
                .onboarding-actions {
                    display: flex;
                }
                .onboarding-actions > :first-child {
                        margin-right: 12px;
                }
                #account_onboardingToken {
                    width: 98%;
                }
                .pxl-line {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: baseline;
                }
                .pxl-item {
                    width: calc(100% - 100px);
                }
                .pxl-txt {
                    width: 100%;
                }
                .account-header {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-row-gap: 24px;
                    margin-bottom: 15px;
                }
                .account-name {
                    font-size: 2em;
                    width: 150%;
                }
                .account-manager-input {
                    width: 70%;
                }
                .form-buttons {
                    justify-self: end;
                    display: grid;
                    grid-template-columns: auto auto;
                    grid-column-gap: 24px;
                    justify-items: end;
                    padding-top: 8px;
                }
                .account-info {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto auto auto auto;
                    grid-column-gap: 24px;
                    grid-auto-flow: column;
                }
                .social-links {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto auto;
                    grid-column-gap: 24px;
                    grid-auto-flow: column;
                    margin-bottom: 15px;
                }
                .ant-collapse-borderless {
                    background-color: white;
                }
                .ant-collapse-header {
                    font-size: 2em;
                }
                .additional-client-info {
                    display: grid;
                    grid-template-columns: 1fr 30%;
                    grid-column-gap: 24px;
                    margin-bottom: 15px;
                }
                .billing-and-fee {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 24px;
                }
            `}
            </style>
        </Layout>
    );
}


// const generateCheckoutPixel = (id, shop) => {
//     if (!shop) return '';
//     let scriptExtra = '';
//     const token = generatePixelToken(id);
//     if (shop && shop.toLowerCase() === 'shopify') {
//         scriptExtra = '?OID={{ order_number }}';
//     }
//     if (shop && shop.toLowerCase() === 'bigcommerce') {
//         scriptExtra = '?oid=%%ORDER_ID%%&amount=%%ORDER_SUBTOTAL_DISCOUNTED_IN_CENTS%%&email=%%ORDER_EMAIL%%&subtotal=%%ORDER_SUBTOTAL_IN_CENTS%%&total=%%ORDER_AMOUNT_IN_CENTS%%';
//     }
//     const scriptBase = getScriptCommonBase();
//     return `${scriptBase}/checkout/CA_${token}${scriptExtra}"></script>`;
// };

// const generateCookiePixel = (id) => {
//     const token = generatePixelToken(id);
//     const scriptBase = getScriptCommonBase();
//     return `${scriptBase}/cookie/CA_${token}"></script>`;
// };

let getAccountManagersParser = (results) => results.map((c) => ({ label: `${c.firstName} ${c.lastName}`, value: c.id }));

let getOnboardingTokenParser = (results) => {
    if (!results.success) return 'Onboarding Link Unavailable';
    const host = process.env.NODE_ENV === 'production' ? 'https://app.current.tech' : 'http://localhost:3002'; 
    return `${host}/onboarding/${results.payload}`;
};

let getClientParser = (results) => {
    const c = results[0];
    return {
        name: c.accountName,
        manager: c.accountManagerId,
        renewalDate: c.renewalDate ? moment(c.renewalDate, 'YYYY/MM/DD') : null,
        contractLink: c.contractLink,
        hubspotLink: c.crmLink,
        ecommerce: c.storeType,
        accountPhoneNumber: c.phoneNumber,
        billingContact: c.billingContact,
        billingEmail: c.billingEmail,
        billingPhone: c.billingPhone,
        paymentVolumeEstimate: c.paymentVolume ? (c.paymentVolume / 100).toFixed(2) : null,
        pixelIntegrated: !!c.checkoutPixelActive,
        ambassadorHub: !!c.hasHub,
        ambassadorName: c.hubName,
        approvals: !!c.requireApprovals,
        ambassadorDomain: c.hubDomain,
        dealType: c.dealType,
        statementCloseDate: c.statementCloseDay,
        paymentDueDate: c.paymentDueDay,
        flatFee: c.saasFlatFee ? (c.saasFlatFee / 100).toFixed(2) : null,
        percentOfSales: c.saasPercentFee ? (c.saasPercentFee * 100).toFixed(1) : null,
        equityAmount: c.equityAmount ? (c.equityAmount * 100).toFixed(2) : null,
        monthlyServiceFee: c.servicesFee ? (c.servicesFee / 100).toFixed(2) : null,
        feePercentOnDebits: c.paymentsDebitFee ? (c.paymentsDebitFee * 100).toFixed(2) : null,
        flatFeeOnCredits: c.paymentsCreditFlatFee ? (c.paymentsCreditFlatFee / 100).toFixed(2) : null,
        feePercentOnCredits: c.paymentsCreditPercentFee ? (c.paymentsCreditPercentFee * 100).toFixed(2) : null,
        taxFee: c.taxReportingFee ? (c.taxReportingFee / 100).toFixed(2) : null,
        ambassadorSalesTracking: c.ambassadorSalesTracking,
        ambassadorTrackingTags: c.ambassadorTrackingTags,
        ambassadorFlatRate: c.defaultFlatRate ? (c.defaultFlatRate / 100).toFixed(2) : null,
        ambassadorSalesPercent: c.defaultPercentOfSales ? (c.defaultPercentOfSales * 100).toFixed(2) : null,
        salesforceId: c.salesforceId,
    };
};

const loadStyle = {
    width: '100%',
    height: '100vh',
    display: 'grid',
    alignItems: 'center'
};

let getLinksParser = (results) => {
    const links = {
        facebookLink: '',
        youtubeLink: '',
        instagramLink: '',
        twitterLink: ''
    };

    results.forEach((l) => {
        // eslint-disable-next-line
        switch (l.network) {
            case 'facebook':
                links.facebookLink = l.url;
                break;
            case 'youtube':
                links.youtubeLink = l.url;
                break;
            case 'instagram':
                links.instagramLink = l.url;
                break;
            case 'twitter':
                links.twitterLink = l.url;
                break;
        }
    });
    return links;
};
