import { useFetcher } from "./useFetcher";
import useSWR from "swr";

function useDecode (...args) {
    let fn;
    let config = {};

    let [key, params] = parseFirstArg(args[0]);

    if (args.length > 2) {
        fn = args[1];
        config = args[2];
    } else {
        if (typeof args[1] === "function") {
        fn = args[1];
        } else if (typeof args[1] === "object") {
        config = args[1];
        }
    }
    let fetcher = fn ? useFetcher(fn) : useFetcher(); // eslint-disable-line

    let useSWRFirstArg = params ? [key, JSON.stringify(params)] : key;

    return useSWR(useSWRFirstArg, fetcher, config);
}

let parseFirstArg = (arg) => {
  let key;
  let params;
  if (typeof arg === "function") {
    try {
      arg = arg();
    } catch (err) {
      // dependencies not ready
      key = "";
      return [key, null];
    }

    return parseFirstArg(arg);
  }

  if (Array.isArray(arg)) {
    key = arg[0];
    params = arg[1];
  } else {
    key = arg;
    params = null;
  }
  return [key, params];
};

export default useDecode;
